import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch orders
export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
  
      const response = await axios.get(
        "https://onebox.lifebanktest.com/api/admin/orders",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log(response)
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Dispatch order
export const dispatchOrder = createAsyncThunk(
  "orders/dispatchOrder",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://onebox.lifebanktest.com/api/admin/process/${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = response.data.facility.pof;
      // console.log(data, "ooo");
      await axios.post(
        `https://onebox.lifebanktest.com/api/admin/dispatch/${id}`,
          data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
     return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    data: [],
    isLoading: false,
    dispatchSent: false,
    error: null,
  },

  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(dispatchOrder.pending, (state) => {
        state.dispatchSent = false;
        state.error = null;
      })
      .addCase(dispatchOrder.fulfilled, (state, action) => {
        state.dispatchSent = true;
        state.error = null;
      })
      .addCase(dispatchOrder.rejected, (state, action) => {
        state.dispatchSent = false;
        state.error = action.payload;
      });
  },
});

export default ordersSlice.reducer;
